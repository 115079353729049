export default [
  {
    title: 'WebSite',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Header Banners',
        route: 'header-banners',
        icon: 'ListIcon',
      },
      {
        title: 'Section Banners',
        route: 'section-banners',
        icon: 'ListIcon',
      },
      {
        title: 'Quick Menu Banners',
        route: 'menu-banners',
        icon: 'ListIcon',
      },
      {
        title: 'Announcements',
        route: 'announcements',
        icon: 'ListIcon',
      },
      {
        title: 'Blogs',
        route: 'blogs',
        icon: 'ListIcon',
      },
      {
        title: 'News',
        route: 'news',
        icon: 'ListIcon',
      },
      {
        title: 'System Pages',
        route: 'system-pages',
        icon: 'ListIcon',
      },
      {
        title: 'Audit Logs',
        route: 'audit-logs',
        icon: 'ListIcon',
      },
    ],
  },
];
