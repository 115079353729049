export default [
  // {
  //   header: "Servicing",
  // },
  {
    title: 'Monitoring',
    icon: 'MonitorIcon',
    children: [
      {
        title: 'Calendar',
        route: 'calendar',
        icon: 'CalendarIcon',
      },
      {
        title: 'Clocking',
        route: 'clocking',
        icon: 'ClockIcon',
      },
      {
        title: 'Map View',
        route: 'map-view',
        icon: 'MapIcon',
      },
      {
        title: 'Today\'s Tasks',
        route: 'task-lists',
        icon: 'ListIcon',
      },
      {
        title: 'Active Tickets',
        route: 'ticket-monitoring',
        icon: 'ListIcon',
      },
      {
        title: 'Active Potentials',
        route: 'ticket-monitoring-potential',
        icon: 'ListIcon',
      },
      {
        title: 'Active Preventive Maintenance',
        route: 'ticket-monitoring-preventive',
        icon: 'ListIcon',
      },
      {
        title: 'Timeline',
        route: 'timeline',
        icon: 'ListIcon',
      },
    ],
  },
];
