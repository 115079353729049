export default [
  // {
  //   header: "Feedback",
  // },
  {
    title: 'Feedback',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Customer Feedbacks',
        route: 'customer-feedbacks',
        icon: 'ListIcon'
      },
      {
        title: 'Staff Feedbacks',
        route: 'staff-feedbacks',
        icon: 'ListIcon'
      },
      {
        title: 'Incidents',
        route: 'incidents',
        icon: 'ListIcon'
      }
    ]
  }
];
