export default [
  // {
  //   header: "Sales",
  // },
  {
    title: 'Sales',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'RFQ',
        route: 'rfq',
        icon: 'ListIcon',
      },
      {
        title: 'Quotation',
        route: 'quotation',
        icon: 'ListIcon',
      },
      {
        title: 'Contracts',
        route: 'contracts',
        icon: 'ListIcon'
      },
      {
        title: 'Sales Order',
        route: 'sales-order',
        icon: 'ListIcon',
      },
      {
        title: 'Sales Invoice',
        route: 'sales-invoice',
        icon: 'ListIcon',
      },
    ],
  },
];
