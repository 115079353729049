export default [
  // {
  //   header: "Masterlists",
  // },
  {
    title: 'Relocation',
    icon: 'ListIcon',
    children: [
      {
        title: 'Relocation Item Masters',
        route: 'relocation-item-masters',
        icon: 'ListIcon',
      },
    ],
  },
];
