export default [
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Dynamic Reporting',
        route: '',
        icon: 'FileTextIcon',
      },
    ],
  },
];
