export default [
  // {
  //   header: "Masterlists",
  // },
  {
    title: 'Definitions',
    icon: 'ListIcon',
    children: [
      {
        title: 'Brands',
        route: 'brands',
        icon: 'ListIcon',
      },
      {
        title: 'Company Groups',
        route: 'company-groups',
        icon: 'ListIcon',
      },
      {
        title: 'Company Types',
        route: 'company-types',
        icon: 'ListIcon',
      },
      {
        title: 'Contract Types',
        route: 'contract-types',
        icon: 'ListIcon',
      },
      {
        title: 'Country Code',
        route: 'country-codes',
        icon: 'ListIcon',
      },
      {
        title: 'Currencies',
        route: 'currencies',
        icon: 'ListIcon',
      },
      {
        title: 'Departments',
        route: 'departments',
        icon: 'ListIcon',
      },
      {
        title: 'Designations',
        route: 'designations',
        icon: 'ListIcon',
      },
      {
        title: 'Document Types',
        route: 'document-types',
        icon: 'ListIcon',
      },
      {
        title: 'GST',
        route: 'gsts',
        icon: 'ListIcon',
      },
      {
        title: 'Manufacturers',
        route: 'manufacturers',
        icon: 'ListIcon',
      },
      {
        title: 'Material Warehouse',
        route: 'material-warehouse',
        icon: 'ListIcon',
      },
      {
        title: 'Product Attributes',
        route: 'product-attributes',
        icon: 'ListIcon',
      },
      {
        title: 'Product Categories',
        route: 'categories',
        icon: 'ListIcon',
      },
      {
        title: 'Refnum Format',
        route: 'ticket-number',
        icon: 'ListIcon',
      },
      {
        title: 'System Code',
        route: 'statuses',
        icon: 'ListIcon',
      },
      {
        title: 'UOMS',
        route: 'uom',
        icon: 'ListIcon',
      },
      {
        title: 'Relocation Categories',
        route: 'relocation-categories',
        icon: 'ListIcon',
      },
    ],
  },
];
