export default [
  // {
  //   header: "Masterlists",
  // },
  {
    title: 'Masterlists',
    icon: 'ListIcon',
    children: [
      {
        title: 'Customers',
        route: 'customers',
        icon: 'ListIcon',
        roles: ['admin'],
      },
      {
        title: 'Competencies',
        route: 'competencies',
        icon: 'ListIcon',
      },
      {
        title: 'Employee',
        route: 'employee',
        icon: 'ListIcon',
      },
      {
        title: 'Exchange Rates',
        route: '',
        icon: 'ListIcon',
      },
      {
        title: 'Groups',
        route: 'groups',
        icon: 'ListIcon',
      },
      {
        title: 'Jobs',
        route: 'jobs',
        icon: 'ListIcon',
      },
      {
        title: 'Products',
        route: 'products',
        icon: 'ListIcon',
      },
      {
        title: 'Services',
        route: 'services',
        icon: 'ListIcon',
      },
      {
        title: 'Sponsee',
        route: 'user-memberships',
        icon: 'ListIcon',
      },
      {
        title: 'Supplier',
        route: 'supplier',
        icon: 'ListIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'ListIcon',
      },
      {
        title: 'Training Subjects',
        route: 'training-subjects',
        icon: 'ListIcon',
      },
      {
        title: 'Traning Partners',
        route: 'training-partners',
        icon: 'ListIcon',
      },
    ],
  },
];
