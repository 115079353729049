export default [
  {
    title: 'Inventory',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Delivery Order',
        route: 'delivery-order',
        icon: 'ListIcon',
      },
      {
        title: 'Stock In/Out',
        route: 'stock-in-out',
        icon: 'ListIcon',
      },
      {
        title: 'Supplier In/Out',
        route: 'supplier-in-out',
        icon: 'ListIcon',
      },
      {
        title: 'Warranty',
        route: 'warranty',
        icon: 'ListIcon',
      },
      {
        title: 'Renewals',
        route: 'renewal',
        icon: 'ListIcon',
      },
    ],
  },
];
