export default [
  {
    title: 'Templates',
    icon: 'MonitorIcon',
    children: [
      {
        title: 'Announcement Templates',
        route: 'announcement-templates',
        icon: 'ListIcon',
      },
      {
        title: 'Checklist Templates',
        route: 'checklists',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Notification Templates',
        route: 'notification-templates',
        icon: 'ListIcon',
      },
      {
        title: 'PDF Designers',
        route: 'pdf-designers',
        icon: 'ListIcon',
      },
      {
        title: 'Report Designers',
        route: 'report-designer',
        icon: 'ListIcon',
      },
    ],
  },
];
