/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'RFQ', route: { name: 'rfq' }, icon: 'ListIcon' },
      { title: 'Quotation', route: { name: 'quotation' }, icon: 'ListIcon' },
      { title: 'Sales Order', route: { name: 'sales-order' }, icon: 'ListIcon' },
      { title: 'Delivery Order', route: { name: 'delivery-order' }, icon: 'ListIcon' },
      { title: 'Sales Invoice', route: { name: 'sales-invoice' }, icon: 'ListIcon' },
      { title: 'Stock In/Out', route: { name: 'stock-in-out' }, icon: 'ListIcon' },
      { title: 'Supplier In/Out', route: { name: 'supplier-in-out' }, icon: 'ListIcon' },
    ],
  },
  files: {
    key: 'file_name',
    data: [],
  },
  contacts: {
    key: 'name',
    data: [],
  },
}
/* eslint-enable */
