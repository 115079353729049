export default [
  {
    title: 'Trainings',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Enrollment',
        route: 'training-management',
        icon: 'ListIcon',
      },
      {
        title: 'Courses',
        route: 'trainings',
        icon: 'ListIcon',
      },
    ],
  },
];
